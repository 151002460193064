a {
  text-decoration: none;
}

.switcher-body{
  height: 100%;
  overflow-y: auto;
  padding: 70px 1.25rem;
  z-index: 10;
}

.switcher-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 1.25rem 1.25rem 0 1.25rem;
  z-index: 11;
  background-color: white;
  border-bottom: 1px solid #ccc;
}

.switcher-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 1.25rem;
  z-index: 12;
  background-color: white;
  border-top: 1px solid #ccc;
}

a{
  cursor: pointer;
}




#lockwindow{
	position:fixed;
	width:100%;
	left:0;
	top:0;
	height:300px;
	z-index:10001;
	apacity:0.5;
  height: 100%;
}
#locker{
	position:fixed;
	width:100%;
	left:0;
	top:0;
	height:300px;
	z-index:10000;
	background:#000;
  height: 100%;
}
#loading,.transparent_class{
	position:fixed;
	z-index:999999;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#000;
	
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	
	/* IE 5-7 */
	filter: alpha(opacity=50);
	
	/* Netscape */
	-moz-opacity: 0.5;
	
	/* Safari 1.x */
	-khtml-opacity: 0.5;
	
	/* Good browsers */
	opacity: 0.5;
}


/* loading spinner */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

